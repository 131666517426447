.filter-box {
 width: fit-content;
 padding: 0.55em 0.75em 0;
 background-color: var(--extra-light-gray);
 margin: 0.25em 0 0;
 border-radius: 25px;
 cursor: pointer;
 display: flex;
 flex-direction: row;
}

.filter-box h4 {
 font-size: 1.1em;
 margin-top: -0.1em;
 color: var(--dark-gray)
}

.filter-box .close-button {
 margin-left: 0.75em;
 margin-top: -0.1em;
}

.filter-box.selected {
 background-color: var(--pink);
}

.filter-box.selected h4 {
 color: var(--black);
}

