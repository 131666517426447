.joint-form-fields {
    display: flex;
}

.joint-form-fields .form-field {
    margin-right: 1em;
}

.alt-checkout {
    display: grid;
}

.shipment-buttons {
    display: flex;
    gap: 1em;
}

.promo-code {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.address-form {
    display: flex;
    flex-direction: column;
}

.paypal-button {
    width: 30vw;
    margin-top: 0.5em;
}

@media screen and (max-width: 650px) {
    .address-form {
        margin-top: 1em;
    }

    .alt-checkout .color-box {
        position: relative;
        width: 67vw;
        margin-right: 1em;
    }

    .shipment-buttons button {
        width: 6.25em;
        margin-right: 0.5em;
    }

    .joint-form-fields {
        flex-direction: column;
    }

    .promo-code {
        flex-direction: column;
    }

    .paypal-button {
        width: 50vw;
    }
}