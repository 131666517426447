@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html a, p{
  font-family: 'Montserrat';
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white);
  color: var(--black)
}

h1 {
  font-family: 'Fraunces', serif;
  font-weight: 700;
  font-style: italic;
  font-size: 3.5em;
  margin-bottom: 0.2em;
  margin-top: 0.5em;
  line-height: 1em;
}

h2 {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 0;
}

h3 {
  font-family: 'Fraunces', serif;
  font-weight: 700;
  font-style: italic;
  font-size: 2em;
}

h4 {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1.25em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

button h4 {
  margin: 0;
  float: inline-end;
  font-size: 0.5em;
}

.float-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/* For glyphs */
h6 {
  font-family:'Courier New', Courier, monospace;
  font-size: 2.5em;
  margin: 0;
}

.center {
  position: absolute;
  transform: translate(-50%, -50%);
}

:root {
  --yellow: #ffcf48;
  --purple: #9462ff;
  --pink: #ff8bcb;
  --orange: #ff8e67;
  --black: #141212;
  --white: #fffef0;
  --dark-gray: #656363;
  --gray: #9e9e9e;
  --light-gray: #d0d0d0;
  --extra-light-gray: #e2e0de;
}

button {
  font-family: 'Montserrat';
  font-size: 1.5em;
  height: 2.5em;
  width: 10em;
  border-radius: 200px;
  border: 0;
  cursor: pointer;
  /* margin-right: 0.75em; */
  vertical-align: top;
  background-color: var(--white);
  box-shadow: 0 0 8px rgba(184, 184, 184, 0.3);
  /* margin-bottom: 0.5em; */
  color: var(--black);
  transition: transform 0.1s;
  transition-timing-function: ease-out;
}

button.primary {
  color: var(--white);
  background-color: var(--black);
  box-shadow: 0 0 8px rgba(97, 97, 97, 0.5);
}

button:not(.disabled).primary:hover {
  box-shadow: 0 0 8px rgba(27, 27, 27, 0.5);
}

button:not(.disabled):hover {
  transform: scale(1.05);
  box-shadow: 0 0 8px rgba(124, 124, 124, 0.3);
}

button.disabled {
  background-color: rgb(65, 65, 65);
  color: var(--dark-gray);
  cursor: initial;
}

/* TODO: add animation for button click */
button.clicked {
  background-color: rgb(168, 168, 168);
  font-weight: 600;
  color: white;
}

.arrows h1 {
  font-weight:500;
  font-family: monospace;
  color: white;
  text-shadow: 0 0 7px rgb(128 128 128 / 50%);
  font-style: normal;
  cursor: pointer;
  font-size: 7vh;
}

.arrow {
  z-index: 1;
  position: absolute;
  margin-top: 22.5vh;
  transition: transform 0.1s;
  transition-timing-function: ease-out;
}

.arrow:hover {
  transform: scale(1.27);
  text-shadow: 0 0 7px rgb(180 180 180 / 50%);
}

.arrow.right {
  margin-left: 40vh;
}

.arrow.left {
  margin-left: 1vh;
}

.container {
  position: absolute;
  top: 15vh;
  left: 10vw;
  width: 80vw;
  height: 77vh;
  display: flex;
}

select {
  border: none;
  background-color: transparent;
  font-family: 'Montserrat';
  font-size: 1.5em;
}

option {
  background-color: var(--white);
}

option:hover {
  background-color: var(--orange);
}

a {
  text-decoration: underline;
  cursor: pointer;
}

.color-box {
    padding: 0 2em 1em;
    border-radius: 25px;
    white-space: pre-line;
    box-shadow: 0 0 8px rgb(200, 200, 200, 0.5);
    margin-bottom: 1.5em;
}

.color-box.disabled {
  background-color: var(--gray);
}

.color0 {
  background-color: var(--yellow);
}

.doodly-box {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center; 
}

.doodly-big-box {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 1.5em;
  padding: 1em 2.5em 2.25em;
  white-space: pre-line;
}

.doodly-long-box {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 1.5em;
  padding: 1em 2.5em 2.25em;
  white-space: pre-line;
}

.doodly-box.box-color1 {
  background-image: url("../../assets/images/boxes/doodly-boxes-01.png");
}

.doodly-box.box-color2 {
  background-image: url("../../assets/images/boxes/doodly-boxes-02.png");
}

.doodly-box.box-color3 {
  background-image: url("../../assets/images/boxes/doodly-boxes-03.png");
}

.doodly-box.box-color0 {
  background-image: url("../../assets/images/boxes/doodly-boxes-00.png");
}

.doodly-big-box.box-color1 {
  background-image: url("../../assets/images/boxes/big1.png");
}

.doodly-big-box.box-color2 {
  background-image: url("../../assets/images/boxes/big2.png");
}

.doodly-big-box.box-color3 {
  background-image: url("../../assets/images/boxes/big3.png");
}

.doodly-big-box.box-color0 {
  background-image: url("../../assets/images/boxes/big0.png");
}

.doodly-long-box.box-color1 {
  background-image: url("../../assets/images/boxes/long1.png");
}

.doodly-long-box.box-color2 {
  background-image: url("../../assets/images/boxes/long2.png");
}

.doodly-long-box.box-color3 {
  background-image: url("../../assets/images/boxes/long3.png");
}

.doodly-long-box.box-color0 {
  background-image: url("../../assets/images/boxes/long0.png");
}

.box-disabled {
  background-image: url("../../assets/images/boxes/big-disabled.png");
}

.color1 {
  background-color: var(--purple);
}

.color2 {
  background-color: var(--pink);
}

.color3 {
  background-color: var(--orange);
}

.collapsible-header {
  display: grid;
  grid-template-columns: 10fr 2em;
  text-align: center;
  margin-left: 2em;
  margin-right: 1em;
  align-items: center;
}

.collapsible-header h6 {
  /* margin-top: 0.5em; */
  margin-left: auto;
  cursor: pointer;
}

h6.arrow-up {
  transform: rotate(180deg);
  transform-origin: 0.3em 0.5em;
}

p {
  white-space: pre-line;
  margin-bottom: 0.25em;
}

/* TODO: fix this weird delay */
/* TODO: able the animation & make the other direction animated if fixed delay */
.collapsible-header h6.collapsed {
  transform: rotate(180deg);
  transform-origin: 0.3em 0.5em;
  /* transition-duration: 1s; */
  /* animation: rotate-down ease 1s forwards; */
}

.form-field {
  position: relative;
  margin-top: 1em;
  margin-bottom: 1.75vh;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.form-field.small {
  width: 10em;
}

.form-field.medium {
  width: 15em;
}

.form-field.large {
  width: 21em;
}

.form-field.extra-large {
  width: 31em;
}

h4 {
  margin-top: 0;
}

.zoomable {
  cursor: url("/src/assets/images/search.png"), sw-resize !important;
}

@keyframes rotate-down {
  100% {
    transform: rotate(180deg);
    transform-origin: 0.3em 0.5em;
  }
}
.collapsible-body.collapsed {
  /* animation: slide 1s ease forwards; */
  display: none;
}

.collapsible-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*#region dropdown*/
.Dropdown-option.disabled {
  text-decoration: line-through;
  cursor: initial;
}

.Dropdown-root {
  border: none !important;
  width: 15vh !important;
  min-width: 100px;
  margin: 0.5em 1em !important;
  font-family: 'Montserrat';
  cursor: pointer !important;
}

.Dropdown-control {
  margin-top: -1.3 !important;
  cursor: pointer !important;
  border-radius: 8px !important;
  background-color: var(--white) !important;
  border: none !important;
}

.Dropdown-menu {
  border-radius: 8px !important;
  background-color: var(--white) !important;
  border: none !important;

}

.Dropdown-option {
  color: var(--black) !important;
  border-color: transparent !important;

}

.Dropdown-option.is-selected {
  color: var(--black) !important;
}

.Dropdown-option.disabled {
  background-color: var(--gray) !important;
  color: var(--black);
  cursor: default !important;
  opacity: 75%;
}
/*#endregion */

.bottom-margin {
  height: 2.5em;
}

.form {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 650px) {
  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.35em;
  }

  h4 {
    font-size: 0.9em;
    margin-top: -0.25em;
  }

  button {
    font-size: 1.25em;
    /* width: 7.5em; */
  }

  .container {
    position: absolute;
    display: flex;
    height: 88%;
    width: 86%;
    left: 7%;
    top: 12%;
    justify-content: center;
  }

  .color-box {
    position: relative;
  }

  .form-field {
    margin-top: 0.5em;
    margin-bottom: 3vh;
  }

  .form-field.small {
    width: 10em;
  }
  
  .form-field.medium {
    width: 12.5em;
  }
  
  .form-field.large {
    width: 15em;
  }

  .arrows h1 {
    font-size: 3em;
  }

  .form-field.extra-large {
    width: 15em;
  }

  .arrow.right {
    margin-left: 36vh;
  }

  .arrow.left {
      margin-left: 2.5vw;
  }

  .arrow {
      z-index: 1;
      position: absolute;
      margin-top: 15vh;
  }
}

@media screen and (min-width: 1000px) {
  .container {
    position: absolute;
    top: 15vh;
    left: 25vw;
    width: 50vw;
    height: 77vh;
    display: flex;
  }
}