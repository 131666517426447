.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2;
  }
   
  .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: var(--yellow);
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    box-shadow: 0 0 8px rgba(36, 36, 36, 0.24);
    overflow: auto;
  }
   
  .close-icon {
    content: 'x';
    font-family: 'Montserrat';
    font-size: 2em;
    cursor: pointer;
    position: fixed;
    right: calc(12.5vw);
    top: calc(12.5vh);
    width: 25px;
    height: 25px;
    z-index: 1;
  }

  @media screen and (min-width: 650px) {
    .close-icon {
      right: calc(14.5vw);
    }
  }