.artwork img {
 object-fit: cover;
 border-radius: 8px;
 width: -webkit-fill-available;
 cursor: pointer;
 transition: transform 0.1s ease-out;
}

.portfolio-preview:hover {
 transform: scale(1.05);
 box-shadow: 0 0 8px rgb(121 121 121 / 30%);
}

.artwork {
 display: flex;
}

.artwork.wide.large {
 grid-column: span 5;
 grid-row: span 4;
}

.artwork.wide.medium {
 grid-column: span 4;
 grid-row: span 3;
}

.artwork.wide.small {
 grid-column: span 3;
 grid-row: span 2;
}

.artwork.tall.large {
 grid-row: span 5;
 grid-column: span 4;
}

.artwork.tall.medium {
 grid-row: span 4;
 grid-column: span 3;
}

.artwork.tall.small {
 grid-row: span 3;
 grid-column: span 2;
}

.artwork.square.large {
 grid-row: span 4;
 grid-column: span 4;
}

.artwork.square.medium {
 grid-row: span 3;
 grid-column: span 3;
}

.artwork.square.small {
 grid-row: span 2;
 grid-column: span 2;
}