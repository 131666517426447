.cart {
    display: flex;
    flex-direction: column;
    width: 75%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
}

.bottom {
    display: block;
    margin-top: auto;
}

.checkout-area {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5em;
    align-items: center;
    text-align: center;
    gap: 1em;
}

.shop-details h4 {
    margin-bottom: 0;
    margin-top: 0;
}

/* TODO: make this be true about container in general, not just cart */
@media screen and (max-width: 1000px) {
    .cart {
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .checkout-area {
        flex-direction: column-reverse;
        margin-bottom: 4em;
    }

    .bottom {
        /* margin-left: 1em; */
    }

    .shop-details {
        margin-left: 0;
        margin-bottom: 0.5em;
    }
}