.artwork-details .main-image {
 height: 65vh;
 width: fit-content;
 overflow: hidden;
 border-radius: 8px;
 max-width: 45vw;
}

.artwork-details {
 display: flex;
 justify-content: center;
 align-items: flex-end;
 column-gap: 2em;
 width: min-content;
 height: 70vh;
}

.artwork-details .main-image img {
 height: 100%;
 object-fit: scale-down;
}

/* TODO: fix arrows width to match image */
.artwork-details .arrows {
 margin-top: 16.5vh;
}

.artwork-details .arrow.left {
 margin-left: 3vh;
}

.artwork-details .arrow.right {
 margin-left: 66vw;
}
/* TODO: handle overflow on mobile */
.minor-images {
 display: flex;
 margin-top: 1em;
 max-height: 10.2vh;
 width: 100%;
}

.minor-images img {
 width: 10.2vh;
 margin-right: 1.4vh;
 object-fit: cover;   
 border-radius: 8px;
 object-position: right;
}

.artwork-details h3 {
 margin-top: 0em;
}

.artwork-details h4 {
 margin-top: -1em;
}

@media screen and (max-width: 650px) {
 .artwork-details .main-image {
  height: unset;
  width: 70vw;
  max-width: unset;
 }

 .artwork-details {
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0;
  margin-top: 0;
 }

 .artwork-details .view {
  margin-bottom: 0;
 }

 .artwork-text {
  margin-top: 1em;
 }

 .artwork-details .arrows {
  margin-top: 6.5vh;
 }
 
 .artwork-details .arrow.left {
  margin-left: 1vh;
 }
 
 .artwork-details .arrow.right {
  margin-left: 6vw;
 }
}