.confirmation-page {
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 align-items: center;
}

.confirmation-page .illustration {
 width: 50%;
 margin-top: 1em;
}

@media screen and (max-width: 650px) {
 .confirmation-page .illustration {
  width: 75%;
  margin-top: 1em;
 }
}