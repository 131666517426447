.contact {
 display: flex;
 flex-direction: column;
}

.contact h1 {
 margin-top: 1vh;
}

.contact-form button {
 margin-top: 1em;
}

.contact .content {
 display: grid;
 grid-template-columns: 1fr 0.5fr;
 align-items: end;
}

.illustrated-page {
 display: grid;
 grid-template-columns: 1fr 0.5fr;
}

.contact .illustration {
 z-index: -1;
 position: fixed;
 width: 20vw;
 top: 40vh;
 left: 60vw;
}

@media screen and (max-width: 650px) {
 .contact h1 {
  margin-top: 0;
  margin-bottom: 0.5em;
 }

 .contact {
  justify-content: initial;
 }

 .contact .illustration {
  left: 60vw;
  top: 30vh;
  width: 30vw;
 }
}