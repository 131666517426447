.product-container {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
}

.view {
    grid-column: 0;
}

.main-product-image {
    position: relative;
    height: 64vh;
    width: 45vh;
    border-radius: 8px;
    box-shadow: 0 0 8px rgb(200, 200, 200);
    display: flex;
    overflow: hidden;
}

.main-product-image img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.minor-product-images {
    display: flex;
    margin-top: 1em;
    max-height: 10.2vh;
    width: 100%;
}

.minor-product-images img {
    width: 10.2vh;
    margin-right: 1.4vh;
    object-fit: cover;   
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(180, 180, 180, 0.3);
    cursor: pointer;
    transition: transform 0.1s;
    transition-timing-function: ease-out;
}

.minor-product-images img:hover {
    transform: scale(1.15);
    box-shadow: 0 0 8px rgba(130, 130, 130, 0.3);;
}

.proudct .info {
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-left: 2em;
}

.buy-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3.5em;
    gap: 0.5em;
}

.proudct .info h1 {
    font-size: 2.75em;
}

.proudct .info h2 {
    margin-top: -1em;
}

.proudct .info p {
    font-size: 1.25em;
}

.variant-picker {
    display: flex;
}

.add-to-cart {
}

/* phone settings */
@media screen and (max-width: 650px) {
    .product-container {
        position: relative;
        flex-direction: column;
        display: flex !important;
        /* left: 0vh; */
        margin-top: 10vh;
        /* width: 80vw; */
        /* transform: translate(0, -50%); */
    }

    .main-product-image {
        position: inherit;
        width: 120%;
        height: 50vh;
    }

    .view {
        position: inherit;
        padding-bottom: 1em;
        width: inherit;
        margin-bottom: 1em;
    }

    .proudct .info {
        position: inherit;
        width: 100%;
        margin-left: 0;
    }

    .proudct .info h1 {
        font-size: 2.25em;
    }

    .proudct .info p {
        font-size: 1em;
    }

    .variant-picker p {
        margin-top: 1.8em;
    }
}