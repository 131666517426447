.shop-policy h1 {
 margin-top: -0.25em;
 margin-bottom: 0.5em;
}

.shop-policy h3 {
 margin-top: 0;
 margin-bottom: 0;
}

.shop-policy {
 padding: 2em;
 flex-direction: column;
}