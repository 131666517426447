.product-preview {
    width: inherit;
    height: 40vh;
    min-height: 260px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: transform 0.1s ease-out;
}

.product-preview:hover {
    transform: scale(1.03);
}

.product-preview h4 {
    margin-top: 0.2em;
    margin-bottom: 1em;
}

.product-preview h3 {
    font-size: 1.75em;
    margin-bottom: 0;
}

.product-preview img {
    width: 20vh;
    height:20vh;
    object-fit: cover;   
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(92, 92, 92, 0.5);
    margin-bottom: 0.2em;
}