.nav-logo {
    position: fixed;
    height: 5.5vh;
    min-height: 1.5em;
    top: 2.4vh;
    left: 50%;
    transform: translate(-50%, -20%);
}

.nav-components {
  position: fixed;
  z-index: 5;
  left: 2vw;
  top: 1vh;
  display:flex;
  flex-direction: row;
  width: 98vw;
}

sup {
  vertical-align: super;
  font-size: 0.7em;
}

.nav-bg {
    position:fixed;
    margin-top: -3%;
    left: 50%;
    height: 15%;
    min-height: 4.5em;
    transform: translate(-60%, 0);
    overflow:hidden;
    z-index: 2;
}

.nav-item {
  font-size: calc(1em + 1vh);
  font-weight: 500;
  margin-right: 1.5vw;
  text-decoration: none;
  color: black;
  transition: transform 0.1s;
  transition-timing-function: ease-out;
}

.nav-item:hover {
  transform: scale(1.1);
}

.nav-item.last {
  margin-left: auto;
  margin-right: 1.2em;
}

.active {
  font-weight: 700;
  font-style: italic;
}

.router {
  position: absolute;
  width: 100%;
  min-height: 100%;
}

/* TODO: figure out how to position in absolute bottom 
insead of sticking to bottom. probably need to rearrange the way
the content page is set - to be relative. but how do I center that way??
figure that out. */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--pink);
  height: 2.1em;
  display: flex;
  justify-content: center;
}

.footer h4 {
  top: 50%;
  transform: translateY(-70%);
  font-size: 1em;
}

.footer .icons {
  margin: 0 auto;
  width: 10em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 0.4em;
  place-items: center;
}

.icon {
  cursor: pointer;
  margin: auto;
  height: 1.25em;
  transition: transform 0.1s;
  transition-timing-function: ease-out;
}

.icon:hover {
  transform: scale(1.2);
}

.email-icon {
  height: 1.45em !important;
  margin-top: -0.1em;
}

.cart-icon {
  height: 1em;
}

/* Ipad/phone settings */
@media screen and (max-width: 650px){
    .nav-logo {
      top: 2.25vh;
      height: 1.9em;
      /* left: 20vw; */
    }

    .nav-item {
      font-size: 0.9em;
      margin-right: 0.65em;
    }

    .nav-bg {
      left: 25%;
      height: 0;
    }

    /* .nav-components {
      left: 40vw;
      width: 60vw;
    } */

    .nav-item.last {
      font-size: 1.1em;
      margin-top: -0.15em;
    }
}

/* XL screen */
@media screen and (min-width: 1444px) {
  .nav-bg {
    margin-top: -3em; 
    transform: translate(-30%, 0);
    left: 25%;
  }
}
