.shop-products {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 2em;
}

@media screen and (max-width: 1000px){
    .shop-products {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 650px){
    .shop-products {
        grid-template-columns: 1fr;
    }
}