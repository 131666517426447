input, textarea {
  background-color: transparent;
  border: none;
  border-bottom: solid var(--black) 0.1em;
  height: 3vh;
  z-index: 2;
  /* position: absolute; */
  font-family: 'Montserrat';
  font-size: 1em;
  font-weight: 700;
  width: inherit;
}

textarea {
  height: auto;
}

input:focus, textarea:focus {
  outline: none;
}

input:focus + label {
  margin-top: -1.25em;
  font-size: 0.75em;
  transition: 0.2s;
  border: none !important;
  color: var(--black)
}

input::placeholder, textarea::placeholder {
  font-weight: 500;
  color: rgb(80, 80, 80)
}

label {
  color: transparent;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1em;
  margin-top: 0;
  position: absolute;
}

.invalid-input-message {
  margin-top: 0.25em;
  margin-bottom: -0.25em;
  font-size: 0.75em;
  font-weight: 500;
  color: var(--black);
}