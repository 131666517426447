.home-menu {
  position: absolute;
  margin-top: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 0.5fr;
}

.home-menu-item {
  display: table;
  transition: transform 0.2s ease-out;
  cursor: pointer;
}

.home-menu-item:hover {
  transform: scale(1.05);
}

.doodle {
  display: table-column;
  background-size: cover;
  background-position: center;
}

.shop-doodle {
  background-image: url("/src/assets/images/doodles/shop.png");
}

.portfolio-doodle {
  background-image: url("/src/assets/images/doodles/portfolio.png");
}

.faq-doodle {
  background-image: url("/src/assets/images/doodles/faq.png");
}

.contact-doodle {
  background-image: url("/src/assets/images/doodles/contact.png");
}

.home-menu-item h1 {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.home-box {
  height: 33vw;
  left: 50%;
  top: 55vh;
  font-size: 1.25vw;
}

.home-box img {
  height: inherit;
}

.home-box h1 {
  position: absolute;
  left: 1.5em;
  top: 0.1em;
}

.home-box .portrait {
  position: absolute;
  left: 62%;
  top: -2em;
}

.home-box h2 {
  position: absolute;
  top: 4em;
  left: 2em;
  margin-right: 13em;
}

@media only screen and (max-width: 480px){
    .home-box {
        height: 105vh;
    }

    .home-box h1 {
        font-size: 8em;
    }

    .home-box h2 {
        font-size: 5.5em;
    }
}

/* Ipad settings */
@media only screen and (max-width: 650px) {
  .home-box {
    height: 100vh;
    top: 78vh;
  }

  .faq-doodle {
    background-position-y: bottom;
  }

  .shop-doodle {
    background-position-y: top;
  }

  .contact-doodle {
    background-position-y: center;
  }

  .portfolio-doodle {
    background-position-y: center;
  }

  .home-box .portrait {
    height: 35vh;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  .home-box h1 {
    font-size: 7em;
    left: 10%;
    top: 14vh;
    z-index: 1;
  }
  
  .home-box h2 {
    font-size: 4.5em;
    left: 6%;
    top: 22vh;
    margin-right: 1em;
  }

  .home-menu {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 0.5fr;
  }
}

/* XL screen */
@media only screen and (min-width: 1444px) {
  .home-box {
    height: 60vh;
    top: 50vh;
  }
}

