.cart-popup {
    margin: 1em 0;
}

.cart-popup h3 {
    text-align: center;
    margin-top: initial;
}

.cart-popup .button-group {
    display: grid;
    grid-template-columns: 0.75fr 1fr 1fr 0.7fr;
    column-gap: 0.75em;
    justify-items: center;
}

.popup-cart-btn {
    grid-column: 2;
}

.popup-shop-btn {
    grid-column: 3;
}

@media screen and (max-width: 650px) {
    .popup-cart-btn {
        grid-row: 1;
        grid-column: 1;
    }
    
    .popup-shop-btn {
        grid-row: 2;
        grid-column: 1;
    }

    /* TODO: fix - there's no space between buttons on mobile */
    .cart-popup .button-group {
        grid-template-columns: auto;
        grid-template-rows: 1fr 1fr;
        row-gap: 0.75em;
    }
}