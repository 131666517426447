.cart-item {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    height: 20vh;
    margin-bottom: 2.5vh;
    padding-left: 3em;
    min-height: 140px;
    align-items: center;
}

.cart-item img {
    width: 15vh;
    height: 15vh;
    object-fit: cover;   
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(92, 92, 92, 0.5);
    margin-top: 1vh;
    min-height: 100px;
    min-width: 100px;
}

.cart-item h3 {
    margin-top: 0.5em;
}

.item-info {
    display: flex;
    flex-direction: column;
    margin-top: -0.5em;
}

.item-info h4 {
    line-height: 1em;
}

.item-info .sub-info {
    margin-top: -1em;
}

.cart-item .delete-button {
    cursor: pointer;
    margin-left: auto;
    color: white;
    text-shadow: 0 0 3px rgba(92, 92, 92, 0.5);
    cursor: pointer;
    padding: 0.25em;
    margin: 0 0.25em 0.25em auto;
    transition: transform 0.1s ease-out;
}

.cart-item .delete-button:hover {
    transform: scale(1.1);
}

/* Phone settings */
@media screen and (max-width: 650px) {
    .item-info {
        margin-top: 0.2em;
    }

    .cart-item .delete-button {
        margin-right: -0.8em;
    }

    .cart-item img {
        margin-left: -0.5em;
    }

    .item-info h4 {
        line-height: 1.2em;
    }
}