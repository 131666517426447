.faq {
 flex-direction: column;
 padding-bottom: 5em;
 height: inherit;
 padding-bottom: 5vh;
}

.faq .main-title {
 margin-top: -0.25em;
}

.faq h4 {
 margin-top: 0.5em;
}

@media screen and (max-width: 650px) {

}