.portfolio .grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-flow: dense;
  justify-content:space-between;
  padding-bottom: 5vh;
}

.portfolio {
  position: absolute;
  top: 13vh;
  left: 5vw;
  width: 90vw;
  height: 100vh;
}

@media screen and (max-width: 650px) {
  .portfolio .grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .portfolio {
    top: 8vh;
  }
}